import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  }
}))

const Wrike = () => {
  const classes = useStyles()

  return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" id="tableTitle" component="div">
            Wrike
          </Typography>
          <Paper className={classes.root}>
          </Paper>
        </Grid>
      </Grid>
  )
}

export default Wrike