import {Redirect, Route} from "react-router-dom"

import { AuthContext } from '../../context/auth'
import React from 'react'

export default function PrivateRoute ({component: Component, ...rest}) {
  const { user } = React.useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(props) => user
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}