import 'date-fns'

import { GET_INVENTORY, UPDATE_INVENTORY } from '../graphql'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useMutation, useQuery } from '@apollo/client'

import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const Inventory = () => {
  const [selectedDateStart, setSelectedDateStart] = React.useState(null)
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(null)
  const tableRef = React.useRef()
  const [ pageSize, setPageSize] = React.useState(0)

  const { loading, error, data, refetch } = useQuery(GET_INVENTORY, {
    variables: { startDate: selectedDateStart, endDate: selectedDateEnd}
  })

  const [ queryUpdateInventory ] = useMutation(UPDATE_INVENTORY, {
    update(cache, data) {
      refetch()
    },
    onError(err) {
      console.log(err)
    }
  })

  const handleDateStartChange = (date) => {
    setSelectedDateStart(date)
  }
  const handleDateEndChange = (date) => {
    setSelectedDateEnd(date)
  }

  if (error) return <Typography variant="h6" id="tableTitle" component="div">Error</Typography>

  const editable = loading ? [] : data.getInventory.map(o => ({ ...o }))

  return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" id="tableTitle" component="div">
            Inventory
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3} alignItems='center'>
              <Grid item xs={6} lg={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-start"
                  label="Date Start"
                  value={selectedDateStart}
                  onChange={handleDateStartChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-end"
                  label="Date End"
                  value={selectedDateEnd}
                  onChange={handleDateEndChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <MaterialTable
            isLoading={loading}
            tableRef={tableRef}
            title='Inventory'
            columns={[
              { title: 'Vendor', field: 'vendor', editable: 'onUpdate',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '40px'
              }},
              { title: 'Type', field: 'type', editable: 'onUpdate',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '40px'
              }},
              { title: 'Description', field: 'description', editable: 'onUpdate',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '40px'
              } },
              { title: 'SKU', field: 'sku', editable: 'onUpdate',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '40px'
              } },
              { title: 'Parsed SKU', field: 'parserSku', editable: 'never',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '40px'
              } },
              { title: 'Qty', field: 'count', editable: 'never',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '20px'
              } },
            ]}
            data={editable}
            onSearchChange={() => {
              const searchLenght = tableRef.current.dataManager.searchedData.length
              if (tableRef.current.dataManager.searchText === "" || tableRef.current.dataManager.searchText.length <= 2) {
                setPageSize(0)
                tableRef.current.dataManager.changePageSize(25)
              }
              if (tableRef.current.dataManager.searchText.length > 2) {
                setPageSize(searchLenght)
                tableRef.current.dataManager.changePageSize(searchLenght === 0 ? 25 : searchLenght)
              }
            }}
            options={{
              showTitle: false,
              actionsColumnIndex: -1,
              pageSize: 25,
              pageSizeOptions: [25, 50, 100, pageSize === 0 ? editable.length : pageSize],
              padding: 'dense',
              exportButton: true,
              headerStyle: {
                fontSize: '11px',
              },
              rowStyle: {
                fontSize: '11px',
              }
            }}
            editable={{
              onRowUpdate: (newData, oldData) => queryUpdateInventory({ variables: newData })
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => refetch(),
              }
            ]}
          />
        </Grid>
      </Grid>
  )
}

export default Inventory