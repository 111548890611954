import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GENERATE_REPORT_PDF } from '../graphql'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/client'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
}))

const Client = () => {
  const classes = useStyles()
  const [ value, setValue ] = React.useState('')
  const [ openBackdrop, setOpenBackdrop] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleChange = (event) => {
    setValue(event.target.value.trim())
  }

  const [ queryGeneratePDFReport ] = useMutation(GENERATE_REPORT_PDF, {
    onCompleted(data) {
      setError(false)
      window.open(data.generatePDFReport.url, "_blank")
      setOpenBackdrop(false)
      setValue('')
    },
    onError(err) {
      setError(true)
      setOpenBackdrop(false)
      setValue('')
    },
    variables: {sn: value}
  })

  const formSubmit = e => {
    e.preventDefault()
    setOpenBackdrop(true)
    queryGeneratePDFReport({variables: {sn: value}})
  }

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const requestnumber = params.get('requestnumber')
    if (requestnumber) {
      setOpenBackdrop(true)
      queryGeneratePDFReport({variables: {sn: requestnumber}})
    }
  }, [queryGeneratePDFReport])

  return (
    <Grid container spacing={5} className={classes.root}>
      <Grid item xs={12} lg={8}>
        <Typography className={classes.title} component="h1" variant="h3">
          DIAWAY SYSTEM INTEGRATION TEST REPORT
        </Typography>
        <Typography className={classes.subtitle} component="h2" variant="h4">
          The DIAWAY servers are stress-tested before shipping out.
        </Typography>
        <div>
          <form noValidate onSubmit={formSubmit}>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={6}>
                <Typography component="h2" variant="h5">
                  Please enter the serial number of your system:
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  error = {error}
                  helperText={error? 'Report not found' : ''}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Serial Number"
                  name="text"
                  autoComplete="text"
                  value={value.sn}
                  onChange={handleChange}
                  autoFocus
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Download pdf file report
            </Button>
          </form>
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color="inherit" />
            <Typography variant="h6">
              Please wait...
            </Typography>
          </Backdrop>
        </div>
      </Grid>
    </Grid>
  )
}

export default Client