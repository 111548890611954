import {Redirect, Route} from "react-router-dom"

import { AuthContext } from '../../context/auth'
import React from 'react'

export default function PublicRoute ({component: Component, ...rest}) {
  const { user } = React.useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(props) => user
        ? <Redirect to={{pathname: '/admin', state: {from: props.location}}} />
        : <Component {...props} />}
    />
  )
}