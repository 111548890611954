import { Alert } from '@material-ui/lab'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'

const DialogNotification = ({message, type}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={true}
      autoHideDuration={6000}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  )
}

export default DialogNotification
