import {
  CssBaseline,
  createMuiTheme
} from "@material-ui/core"

import { AuthProvider } from './context/auth'
import React from 'react'
import {BrowserRouter as Router} from "react-router-dom"
import Routers from './Routers'
import { ThemeProvider } from "@material-ui/styles"

const theme = createMuiTheme({
  palette: {
    type: "light"
  },
  overrides: {
    MuiTableRow: {
      root: {
        "&:hover": {
          backgroundColor: "#f2f2f2"
        }
      }
    }
  }
})

function App() {

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <Routers />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
