import { Redirect, Route, Switch } from 'react-router-dom'

import Client from './pages/Client'
import Inventory from './pages/Inventory'
import Layout from './components/Layout'
import Login from './pages/Login'
import NoMatch from './pages/NoMatch'
import PrivateRoute from './components/Route/PrivateRoute'
import PublicRoute from './components/Route/PublicRoute'
import React from 'react'
import Reports from './pages/Reports'
import StatReport from './pages/StatReport'
import Support from './pages/Support'
// import Tech from './pages/Tech'
import UploadReport from './pages/UploadReport'
import Users from './pages/Users'
import Wrike from './pages/Wrike'

// import Dashboard from './pages/Dashboard'

const Routers = () => {
	return (
		<Switch>
			<Route path='/' exact component={Client} />
			<Route path='/tech' exact>
				<Redirect to='/login' />
			</Route>
			<PublicRoute path='/login' component={Login} />
			<Route path='/admin/:path?' exact>
				<Layout>
					<Switch>
						<PrivateRoute path='/admin' exact component={Reports} />
						{/* <PrivateRoute path='/admin/reports' component={Reports}/> */}
						<PrivateRoute path='/admin/upload' component={UploadReport} />
						<PrivateRoute path='/admin/inventory' component={Inventory} />
						<PrivateRoute path='/admin/support' component={Support} />
						<PrivateRoute path='/admin/wrike' component={Wrike} />
						<PrivateRoute path='/admin/users' component={Users} />
						<PrivateRoute path='/admin/statreports' component={StatReport} />
						<PrivateRoute path='*' component={NoMatch} />
					</Switch>
				</Layout>
			</Route>
			<Route path='*' component={NoMatch} />
		</Switch>
	)
}

export default Routers
