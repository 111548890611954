import { GENERATE_REPORT_PDF, GET_REPORTS } from '../graphql'
import { useMutation, useQuery } from '@apollo/client'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DetailPanelReports from '../components/DetailPanelReports'
import DialogDeleteReports from '../components/DialogDeleteReports'
import DialogUpdateDeal from '../components/DialogUpdateDeal'
import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import logoPDF from '../images/pdf-download-icon.png'
import logoTXT from '../images/txt-download-icon.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	copy: {
		color: '#2020f0',
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		display: 'flex',
		flexDirection: 'column',
	},
}))

const Reports = () => {
	const classes = useStyles()
	const { loading, error, data, refetch } = useQuery(GET_REPORTS)
	const [showPopupEdit, setShowPopupEdit] = React.useState(false)
	const [showPopupDelete, setShowPopuDelete] = React.useState(false)
	const [openBackdrop, setOpenBackdrop] = React.useState(false)
	const [idPDF, setIdPDF] = React.useState('')
	const [dataEdit, setDataEdit] = React.useState([])
	const tableRef = React.useRef()
	const [pageSize, setPageSize] = React.useState(0)

	const [queryGeneratePDFReport] = useMutation(GENERATE_REPORT_PDF, {
		onCompleted(data) {
			window.open(data.generatePDFReport.url, '_blank')
			setIdPDF('')
			setOpenBackdrop(false)
		},
		onError(err) {
			setIdPDF('')
			setOpenBackdrop(false)
		},
		variables: { id: idPDF },
	})

	const genArrayId = reports => {
		const reportID = reports.map(val => val.id)
		setDataEdit([...dataEdit, ...reportID])
	}

	React.useEffect(() => {
		if (idPDF !== '') {
			setOpenBackdrop(true)
			queryGeneratePDFReport()
		}
	}, [idPDF, queryGeneratePDFReport])

	const generatePDFReport = (event, id) => {
		event.preventDefault()
		setIdPDF(id)
	}

	if (error)
		return (
			<Typography variant='h6' id='tableTitle' component='div'>
				Error
			</Typography>
		)

	const editable = loading ? [] : data.reports.map(o => ({ ...o }))

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant='h6' id='tableTitle' component='div'>
						Reports
					</Typography>
					<MaterialTable
						isLoading={loading}
						tableRef={tableRef}
						title='Reports'
						columns={[
							{
								title: 'Deal Link',
								field: 'dealText',
								render: rowData => {
									if (rowData.dealUrl && rowData.dealText)
										return (
											<a
												href={rowData.dealUrl}
												target='_blank'
												rel='noopener noreferrer'
											>
												{rowData.dealText}
											</a>
										)
									if (rowData.dealUrl && !rowData.dealText)
										return (
											<a
												href={rowData.dealUrl}
												target='_blank'
												rel='noopener noreferrer'
											>
												{rowData.dealUrl}
											</a>
										)
									if (!rowData.dealUrl) return rowData.dealText
								},
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'Deal Url',
								field: 'dealUrl',
								editable: 'never',
								hidden: true,
								searchable: true,
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'boardSerialOld',
								field: 'boardSerialOld',
								editable: 'never',
								hidden: true,
								searchable: true,
							},
							{
								title: 'productSerialOld',
								field: 'productSerialOld',
								editable: 'never',
								hidden: true,
								searchable: true,
							},
							{
								title: 'snDevice',
								field: 'snDevice',
								editable: 'never',
								hidden: true,
								searchable: true,
							},
							{
								title: 'modelDevice',
								field: 'modelDevice',
								editable: 'never',
								hidden: true,
								searchable: true,
							},
							{
								title: 'snMemory',
								field: 'snMemory',
								editable: 'never',
								hidden: true,
								searchable: true,
							},
							{
								title: 'modelMemory',
								field: 'modelMemory',
								editable: 'never',
								hidden: true,
								searchable: true,
							},
							{
								title: 'snNic',
								field: 'snNic',
								editable: 'never',
								hidden: true,
								searchable: true,
							},
							{
								title: 'modelNic',
								field: 'modelNic',
								editable: 'never',
								hidden: true,
								searchable: true,
							},
							{
								title: 'Location',
								field: 'location',
								editable: 'never',
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'UUID',
								field: 'uuid',
								editable: 'never',
								render: rowData => {
									return (
										<CopyToClipboard
											text={`https://sit.diaway.com/?requestnumber=${rowData.uuid}`}
										>
											<span className={classes.copy}>{rowData.uuid}</span>
										</CopyToClipboard>
									)
								},
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								field: 'pdfUrl',
								sorting: false,
								render: rowData => {
									if (!rowData.boardSerial) return null
									return (
										<div style={{ display: 'flex' }}>
											<a
												href='/#'
												onClick={e => {
													generatePDFReport(e, rowData.id)
												}}
											>
												<img src={logoPDF} alt='pdf' width='15px' />
											</a>
											<a
												href={rowData.txtUrl}
												target='_blank'
												rel='noopener noreferrer'
											>
												<img
													src={logoTXT}
													alt='txt'
													width='15px'
													style={{ marginLeft: '3px' }}
												/>
											</a>
										</div>
									)
								},
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									width: '40px',
								},
							},
							{
								title: 'System SN',
								field: 'systemSN',
								editable: 'never',
								render: rowData => {
									return (
										<CopyToClipboard text={rowData.systemSN}>
											<span className={classes.copy}>{rowData.systemSN}</span>
										</CopyToClipboard>
									)
								},
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'Chassis SKU',
								field: 'productName',
								editable: 'never',
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'Chassis SN',
								field: 'productSerial',
								editable: 'never',
								render: rowData => {
									return (
										<CopyToClipboard
											text={`https://sit.diaway.com/?requestnumber=${rowData.productSerial}`}
										>
											<span className={classes.copy}>
												{rowData.productSerial}
											</span>
										</CopyToClipboard>
									)
								},
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'MB SKU',
								field: 'boardProduct',
								editable: 'never',
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'MB SN',
								field: 'boardSerial',
								editable: 'never',
								render: rowData => {
									return (
										<CopyToClipboard
											text={`https://sit.diaway.com/?requestnumber=${rowData.boardSerial}`}
										>
											<span className={classes.copy}>
												{rowData.boardSerial}
											</span>
										</CopyToClipboard>
									)
								},
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'Tests Dated',
								field: 'date',
								editable: 'never',
								type: 'datetime',
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'BIOS',
								field: 'biosColor',
								editable: 'never',
								render: rowData => {
									if (rowData.bios)
										return `${rowData.bios.substr(0, 10)} ${rowData.biosDate}`
								},
								cellStyle: rowData => ({
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
									backgroundColor: rowData || null,
								}),
							},
							{
								title: 'BIOS',
								field: 'bios',
								editable: 'never',
								hidden: true,
								searchable: true,
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
							{
								title: 'BMC',
								field: 'bmcColor',
								editable: 'never',
								render: rowData => rowData.bmc,
								cellStyle: rowData => ({
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
									backgroundColor: rowData || null,
								}),
							},
							{
								title: 'BMC',
								field: 'bmc',
								editable: 'never',
								hidden: true,
								searchable: true,
								cellStyle: {
									paddingLeft: '3px',
									paddingRight: '3px',
									whiteSpace: 'nowrap',
								},
							},
						]}
						data={editable}
						onSearchChange={() => {
							const searchLenght =
								tableRef.current.dataManager.searchedData.length
							if (
								tableRef.current.dataManager.searchText === '' ||
								tableRef.current.dataManager.searchText.length <= 2
							) {
								setPageSize(0)
								tableRef.current.dataManager.changePageSize(25)
							}
							if (tableRef.current.dataManager.searchText.length > 2) {
								setPageSize(searchLenght)
								tableRef.current.dataManager.changePageSize(
									searchLenght === 0 ? 25 : searchLenght
								)
							}
						}}
						options={{
							showTitle: false,
							paging: true,
							pageSize: 25,
							pageSizeOptions: [
								25,
								50,
								100,
								pageSize === 0 ? editable.length : pageSize,
							],
							padding: 'dense',
							exportButton: true,
							selection: true,
							headerStyle: {
								paddingLeft: '0',
								paddingRight: '0',
								fontSize: '11px',
								whiteSpace: 'nowrap',
							},
							rowStyle: {
								fontSize: '11px',
							},
						}}
						actions={[
							{
								tooltip: 'Remove All Selected Reports',
								icon: 'delete',
								onClick: (evt, data) => {
									genArrayId(data)
									setShowPopuDelete(true)
								},
							},
							{
								tooltip: 'Edit All Selected Reports',
								icon: 'edit',
								onClick: (evt, data) => {
									genArrayId(data)
									setShowPopupEdit(true)
								},
							},
							{
								icon: 'refresh',
								tooltip: 'Refresh Data',
								isFreeAction: true,
								onClick: () => refetch(),
							},
						]}
						detailPanel={[
							{
								tooltip: 'Show Detail',
								render: rowData => {
									return <DetailPanelReports id={rowData.id} />
								},
							},
						]}
					/>
				</Grid>
			</Grid>
			<DialogUpdateDeal
				dataEdit={dataEdit}
				showPopupEdit={showPopupEdit}
				setShowPopupEdit={setShowPopupEdit}
				setDataEdit={setDataEdit}
			/>
			<DialogDeleteReports
				dataEdit={dataEdit}
				showPopupDelete={showPopupDelete}
				setShowPopuDelete={setShowPopuDelete}
				setDataEdit={setDataEdit}
			/>
			<Backdrop className={classes.backdrop} open={openBackdrop}>
				<CircularProgress color='inherit' />
				<Typography variant='h6'>Please wait...</Typography>
			</Backdrop>
		</>
	)
}

export default Reports
