import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'

import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import jwtDecode from 'jwt-decode'
import React from 'react'
import App from './App'

const httpLink = createUploadLink({
	// uri: 'http://localhost:3001/graphql',
	uri: process.env.URI_API,
})

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('token')
	// return the headers to the context so httpLink can read them
	if (token) {
		const { exp } = jwtDecode(token)
		if (exp * 1000 < Date.now()) {
			localStorage.clear()
			document.location.href = '/login'
		}
	}

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	}
})

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					reports: {
						merge(existing, incoming) {
							return incoming
						},
					},
				},
			},
		},
	}),
})

export default (
	<ApolloProvider client={client}>
		<App />
	</ApolloProvider>
)
