import Grid from '@material-ui/core/Grid'
import React from 'react'
import { useQuery } from '@apollo/client'
import {GET_REPORT_DETAIL} from '../graphql'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontSize: '12px',
    margin: '10px 30px'
  },
  newLine: {
    whiteSpace: 'pre-line'
  },
}))

const DetailPanelReports = (id) => {
  const classes = useStyles()
  const { loading, data } = useQuery(GET_REPORT_DETAIL, {
    variables: id
  })

  if (!data || loading) {return (<Grid container><p>Loading...</p></Grid>)}
  if (!data.getReportDetail.systemCpu){return (<Grid container><p>Not found</p></Grid>)}

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1}>
          <strong>CPU:</strong>
        </Grid>
        <Grid item xs={3}>
          {data.getReportDetail.systemCpu}
        </Grid>
        <Grid item xs={1}>
          <strong>STORAGE:</strong>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.newLine}>{data.getReportDetail.systemBlockDevice}</div>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={1}>
          <strong>RAM:</strong>
        </Grid>
        <Grid item xs={3}>
          {data.getReportDetail.systemRam}
        </Grid>
        <Grid item xs={1}>
          <strong>STORAGE HBA:</strong>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.newLine}>{data.getReportDetail.hbaProduct ? `${data.getReportDetail.hbaProduct}\n ${data.getReportDetail.hbaBios}\n ${data.getReportDetail.hbaFw}` : 'Not Found'}</div>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={1}>
          <strong>NIC:</strong>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.newLine}>{data.getReportDetail.systemNic}</div>
        </Grid>
      </Grid>
    </div>
  )
}

export default DetailPanelReports
