import 'date-fns'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import { GET_STAT_REPORTS } from '../graphql'
import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useQuery } from '@apollo/client'

const StatReports = () => {
  const tableRef = React.useRef()
  const [ pageSize, setPageSize] = React.useState(0)
  const [selectedDateStart, setSelectedDateStart] = React.useState(null)
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(null)
  const { loading, error, data, refetch } = useQuery(GET_STAT_REPORTS, {
    variables: { startDate: selectedDateStart, endDate: selectedDateEnd}
  })

  const handleDateStartChange = (date) => {
    setSelectedDateStart(date)
  }
  const handleDateEndChange = (date) => {
    setSelectedDateEnd(date)
  }
  if (error) return <Typography variant="h6" id="tableTitle" component="div">Error</Typography>

  const editable = loading ? [] : data.statReports.map(o => ({ ...o }));

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" id="tableTitle" component="div">
            Statistics of downloaded reports
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3} alignItems='center'>
              <Grid item xs={6} lg={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-start"
                  label="Date Start"
                  value={selectedDateStart}
                  onChange={handleDateStartChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-end"
                  label="Date End"
                  value={selectedDateEnd}
                  onChange={handleDateEndChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <MaterialTable
            isLoading={loading}
            tableRef={tableRef}
            title='Statistics of downloaded reports'
            columns={[
              { title: 'Date of uploaded reports', field: 'date', editable: 'never', type: 'datetime',
              cellStyle: {
                paddingLeft: '3px',
                paddingRight: '3px',
                whiteSpace: 'nowrap'
              }},
              { title: 'UUID', field: 'uuid', editable: 'never',
              cellStyle: {
                paddingLeft: '3px',
                paddingRight: '3px',
                whiteSpace: 'nowrap'
              }},
              { title: 'Client ID', field: 'clientID', editable: 'never',
              cellStyle: {
                paddingLeft: '3px',
                paddingRight: '3px',
                whiteSpace: 'nowrap'
              }},
            ]}
            data={editable}
            onSearchChange={() => {
              const searchLenght = tableRef.current.dataManager.searchedData.length
              if (tableRef.current.dataManager.searchText === "" || tableRef.current.dataManager.searchText.length <= 2) {
                setPageSize(0)
                tableRef.current.dataManager.changePageSize(25)
              }
              if (tableRef.current.dataManager.searchText.length > 2) {
                setPageSize(searchLenght)
                tableRef.current.dataManager.changePageSize(searchLenght === 0 ? 25 : searchLenght)
              }
            }}
            options={{
              showTitle: false,
              paging: true,
              pageSize: 25,
              pageSizeOptions: [25, 50, 100, pageSize === 0 ? editable.length : pageSize],
              padding: 'dense',
              exportButton: true,
              selection: true,
              headerStyle: {
                paddingLeft: '0',
                paddingRight: '0',
                fontSize: '11px',
                whiteSpace: 'nowrap'
              },
              rowStyle: {
                fontSize: '11px',
              }
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => refetch(),
              }
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default StatReports