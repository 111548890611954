import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const NoMatch = () => {

  return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" id="tableTitle" component="div">
            NoMatch
          </Typography>
        </Grid>
      </Grid>
  )
}

export default NoMatch