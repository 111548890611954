import { gql } from '@apollo/client'

export const GET_REPORTS = gql`
	{
		reports {
			id
			dealUrl
			dealText
			uuid
			systemSN
			productSerial
			productSerialOld
			productName
			boardSerial
			boardSerialOld
			boardProduct
			date
			bios
			biosDate
			biosColor
			bmc
			bmcColor
			txtUrl
			pdfUrl
			location
			snDevice
			modelDevice
			snMemory
			modelMemory
			snNic
			modelNic
		}
	}
`

export const GET_MBSKU = gql`
	{
		getMbSku {
			mbSku
		}
	}
`
export const GET_BIOS_BMC = gql`
	query getBiosBmc($item: String!, $sku: String!) {
		getBiosBmc(item: $item, sku: $sku) {
			id
			sku
			version
			dateStart
			dateEnd
		}
	}
`

export const CREATE_BIOS_BMC = gql`
	mutation (
		$sku: String!
		$type: String!
		$version: String!
		$dateStart: Date!
		$dateEnd: Date
	) {
		createBiosBmc(
			sku: $sku
			type: $type
			version: $version
			dateStart: $dateStart
			dateEnd: $dateEnd
		) {
			sku
			version
			dateStart
			dateEnd
		}
	}
`

export const UPDATE_BIOS_BMC = gql`
	mutation (
		$id: ID!
		$sku: String!
		$type: String!
		$version: String!
		$dateStart: Date!
		$dateEnd: Date
	) {
		updateBiosBmc(
			id: $id
			sku: $sku
			type: $type
			version: $version
			dateStart: $dateStart
			dateEnd: $dateEnd
		) {
			sku
			version
			dateStart
			dateEnd
		}
	}
`

export const DELETE_BIOS_BMC = gql`
	mutation deleteBiosBmc(
		$id: ID!
		$sku: String!
		$type: String!
		$version: String!
	) {
		deleteBiosBmc(id: $id, sku: $sku, type: $type, version: $version) {
			msg
		}
	}
`

export const SINGLE_UPLOAD = gql`
	mutation ($file: Upload!, $date: Date) {
		singleUpload(file: $file, date: $date) {
			filename
			mimetype
			encoding
		}
	}
`

export const UPDATE_REPORTS = gql`
	mutation updateReports(
		$reportsID: [String!]!
		$dealText: String
		$dealUrl: String
		$location: String
	) {
		updateReports(
			reportsID: $reportsID
			dealText: $dealText
			dealUrl: $dealUrl
			location: $location
		) {
			id
			dealUrl
			dealText
			uuid
			systemSN
			productSerial
			productName
			boardSerial
			boardProduct
			date
			bios
			biosDate
			biosColor
			bmc
			bmcColor
			txtUrl
			pdfUrl
			location
			snDevice
			modelDevice
			snMemory
			modelMemory
			snNic
			modelNic
		}
	}
`

export const DELETE_REPORT = gql`
	mutation deleteReports($reportsID: [String!]!) {
		deleteReports(reportsID: $reportsID) {
			msg
		}
	}
`

export const GENERATE_REPORT_PDF = gql`
	mutation generatePDFReport($id: ID, $sn: String) {
		generatePDFReport(id: $id, sn: $sn) {
			url
		}
	}
`

export const UPLOAD_AND_GENERATE = gql`
	mutation ($file: Upload!, $clientID: String) {
		uploadAndGenerate(file: $file, clientID: $clientID) {
			url
		}
	}
`

export const GET_INVENTORY = gql`
	query getInventory($startDate: Date, $endDate: Date) {
		getInventory(startDate: $startDate, endDate: $endDate) {
			vendor
			type
			description
			sku
			parserSku
			count
		}
	}
`
export const UPDATE_INVENTORY = gql`
	mutation updateInventory(
		$vendor: String
		$type: String
		$description: String
		$sku: String
		$parserSku: String!
	) {
		updateInventory(
			vendor: $vendor
			type: $type
			description: $description
			sku: $sku
			parserSku: $parserSku
		) {
			msg
		}
	}
`

export const GET_USERS = gql`
	{
		users {
			id
			username
			email
			date
		}
	}
`

export const CREATE_USER = gql`
	mutation createUser($email: String!, $username: String!, $password: String!) {
		createUser(email: $email, username: $username, password: $password) {
			id
			username
			email
			date
		}
	}
`

export const UPDATE_USER = gql`
	mutation updateUser(
		$id: ID!
		$email: String!
		$username: String!
		$password: String
	) {
		updateUser(
			id: $id
			email: $email
			username: $username
			password: $password
		) {
			id
			username
			email
			date
		}
	}
`
export const DELETE_USER = gql`
	mutation deleteUser($id: ID!) {
		deleteUser(id: $id) {
			id
			username
			email
			date
		}
	}
`

export const GET_STAT_REPORTS = gql`
	query statReports($startDate: Date, $endDate: Date) {
		statReports(startDate: $startDate, endDate: $endDate) {
			id
			date
			uuid
			clientID
		}
	}
`

export const GET_REPORT_DETAIL = gql`
	query getReportDetail($id: ID!) {
		getReportDetail(id: $id) {
			systemCpu
			systemRam
			systemNic
			systemBlockDevice
			hbaFw
			hbaBios
			hbaProduct
		}
	}
`
