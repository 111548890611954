import Button from '@material-ui/core/Button'
import { DELETE_REPORT, GET_REPORTS } from '../graphql'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import React from 'react'
import { useMutation } from '@apollo/client'


const DialogDeleteReports = ({dataEdit, showPopupDelete, setShowPopuDelete, setDataEdit}) => {
  const [ deleteReport ] = useMutation(DELETE_REPORT, {
    update(proxy) {
      setDataEdit([])
      setShowPopuDelete(false)
      const data = proxy.readQuery({query: GET_REPORTS})
      const newData = data.reports.filter(el => !dataEdit.includes(el.id))
      proxy.writeQuery({query: GET_REPORTS, data: { ...data, reports: newData }})
    },
    onError(err) {
      console.log(err)
      setDataEdit([])
      setShowPopuDelete(false)
    },
    variables: {reportsID: dataEdit}
  })

  const handlePopupClose = () => {
    setDataEdit([])
    setShowPopuDelete(false)
  }

  const handlePopupSave = () => {
    deleteReport()
  }

  return (
    <Dialog
      open={showPopupDelete}
      onClose={handlePopupClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`You want to delete ${dataEdit.length} rows`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePopupClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handlePopupSave} color="secondary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogDeleteReports
