import FormControl from '@material-ui/core/FormControl'
import { GET_MBSKU } from '../graphql'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Select from '@material-ui/core/Select'
import TableSupport from '../components/TableSupport'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))

const Support = () => {
  const classes = useStyles()
  const [sku, setSku] = React.useState('')
  const { loading, error, data } = useQuery(GET_MBSKU)

  if (error) return <Typography variant="h6" id="tableTitle" component="div">Error loading SKU</Typography>

  const handleChangeSelect = (event) => {
    setSku(event.target.value);
  }

  return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" id="tableTitle" component="div">
            Support
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">SKU</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sku}
              onChange={handleChangeSelect}
            >
              {!loading && data.getMbSku.map((item) => <MenuItem key={item.mbSku} value={item.mbSku}>{item.mbSku}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TableSupport title='BIOS' type='bios' sku={sku}/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TableSupport title='BMC' type='bmc' sku={sku}/>
        </Grid>
      </Grid>
  )
}

export default Support