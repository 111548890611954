import { CREATE_BIOS_BMC, DELETE_BIOS_BMC, GET_BIOS_BMC, UPDATE_BIOS_BMC } from '../graphql'
import { useMutation, useQuery } from '@apollo/client'

import DialogNotification from './DialogNotification'
import MaterialTable from 'material-table'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const TableSupport = ({sku = '', type, title}) => {
  const { loading, error, data, refetch } = useQuery(GET_BIOS_BMC, {
    variables: { item: type, sku: sku}
  })

  const [ createBiosBmc, { error: createBiosBmcError } ] = useMutation(CREATE_BIOS_BMC, {
    refetchQueries: [
      { query: GET_BIOS_BMC, variables: { item: type, sku: sku} }
    ],
    awaitRefetchQueries: true,
  })

  const [ deleteBiosBmc ] = useMutation(DELETE_BIOS_BMC, {
    refetchQueries: [
      { query: GET_BIOS_BMC, variables: { item: type, sku: sku} }
    ],
    awaitRefetchQueries: true,
  })

  const [ updateBiosBmc ] = useMutation(UPDATE_BIOS_BMC, {
    refetchQueries: [
      { query: GET_BIOS_BMC, variables: { item: type, sku: sku} }
    ],
    awaitRefetchQueries: true,
  })

  if (error) return <Typography variant="h6" id="tableTitle" component="div">Error loading data</Typography>

  return (
    <>
      <MaterialTable
        title={title}
        isLoading={loading}
        columns={[
          { title: 'Version', field: 'version', validate: rowData => rowData.version !== '' && rowData.version !== undefined,
            cellStyle: {
              whiteSpace: 'nowrap',
          }},
          { title: 'Date Start', field: 'dateStart', type: 'date', initialEditValue: new Date(), validate: rowData => rowData.dateStart !== null,
            cellStyle: {
              whiteSpace: 'nowrap',
          }},
          { title: 'Date End', field: 'dateEnd', type: 'date',
            cellStyle: {
              whiteSpace: 'nowrap',
          } },
        ]}
        data={loading ? [] : data.getBiosBmc.map(o => ({ ...o }))}
        options={{
          search: false,
          actionsColumnIndex: -1,
          pageSize: 5,
          pageSizeOptions: [5, 10, 25],
          padding: 'dense',
          exportButton: false,
          headerStyle: {
            fontSize: '11px',
          },
          rowStyle: {
            fontSize: '11px',
          }
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
                createBiosBmc({ variables: {sku, type, version: newData.version, dateStart: newData.dateStart, dateEnd: newData.dateEnd} })
                resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              updateBiosBmc({ variables: {id: oldData.id, sku, type, version: newData.version, dateStart: newData.dateStart, dateEnd: newData.dateEnd} })
              resolve()
          }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              deleteBiosBmc({ variables: {id: oldData.id, sku, type, version: oldData.version} })
              resolve()
            }),
        }}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => refetch(),
          }
        ]}
      />
      {createBiosBmcError && <DialogNotification message={createBiosBmcError.message} type='error' />}
    </>
  )
}

export default TableSupport
