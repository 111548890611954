import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { UPDATE_REPORTS } from '../graphql'
import { useMutation } from '@apollo/client'

const DialogUpdateDeal = ({dataEdit, showPopupEdit, setShowPopupEdit, setDataEdit}) => {
  const [ values, setValues ] = React.useState({dealText: '', dealUrl: '', location: ''})

  const [ updateDeal ] = useMutation(UPDATE_REPORTS, {
    update(proxy, data) {
      setShowPopupEdit(false)
      setDataEdit([])
    },
    onError(err) {
      setShowPopupEdit(false)
      setDataEdit([])
    },
    variables: {reportsID: dataEdit, ...values}
  })

  const handlePopupEditClose = () => {
    setDataEdit([])
    setShowPopupEdit(false)
  }

  const handlePopupEditSave = () => {
    updateDeal()
  }

  const onChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Dialog
      open={showPopupEdit}
      onClose={handlePopupEditClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Deal Text"
            name="dealText"
            value={values.dealText}
            onChange={onChange}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Deal Url"
            name="dealUrl"
            value={values.dealUrl}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Location"
            name="location"
            value={values.location}
            onChange={onChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePopupEditClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handlePopupEditSave} color="primary" autoFocus>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogUpdateDeal
