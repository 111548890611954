import 'date-fns'

import { GET_REPORTS, SINGLE_UPLOAD } from '../graphql'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from "@apollo/client"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  }
}))

const UploadReport = () => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [mutate, { loading, error }] = useMutation(SINGLE_UPLOAD, {
    refetchQueries: [
      { query: GET_REPORTS }
    ],
    awaitRefetchQueries: true,
  })

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const onChange = ({ target: { validity, files: [file] } }) => {
    if (validity.valid) mutate({ variables: {file, date: selectedDate} })
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>{JSON.stringify(error, null, 2)}</div>

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" id="tableTitle" component="div">
          Upload Report
        </Typography>
        <Paper className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3} alignItems='center'>
              <Grid item xs={12} lg={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Test date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <br />
          <input type="file" multiple required onChange={onChange} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default UploadReport
