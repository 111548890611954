import { CREATE_USER, DELETE_USER, GET_USERS, UPDATE_USER } from '../graphql'
import { useMutation, useQuery } from '@apollo/client'

import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const Users = () => {
  const { loading, error, data, refetch } = useQuery(GET_USERS)

  const [ queryCreateUser ] = useMutation(CREATE_USER, {
    refetchQueries: [
      { query: GET_USERS }
    ],
    awaitRefetchQueries: true,
  })
  const [ queryUpdateUser ] = useMutation(UPDATE_USER, {
    refetchQueries: [
      { query: GET_USERS }
    ],
    awaitRefetchQueries: true,
  })
  const [ queryDeleteUser ] = useMutation(DELETE_USER, {
    refetchQueries: [
      { query: GET_USERS }
    ],
    awaitRefetchQueries: true,
  })

  if (error) return <Typography variant="h6" id="tableTitle" component="div">Error</Typography>

  const editable = loading ? [] : data.users.map(o => ({ ...o }))

  return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" id="tableTitle" component="div">
            Users
          </Typography>
          <MaterialTable
            isLoading={loading}
            title="Users"
            columns={[
              { title: 'Username', field: 'username'},
              { title: 'Email', field: 'email'},
              { title: 'Password', field: 'password'},
              { title: 'Create Date', field: 'date', editable: 'never', type: 'datetime'},
            ]}
            data={editable}
            options={{
              showTitle: false,
              actionsColumnIndex: -1,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20],
              padding: 'dense',
              exportButton: false,
              headerStyle: {
                fontSize: '11px',
              },
              rowStyle: {
                fontSize: '11px',
              }
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                    queryCreateUser({ variables: newData })
                    resolve()
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  queryUpdateUser({ variables: newData })
                  resolve()
              }),
              onRowDelete: oldData => 
                new Promise((resolve, reject) => {
                  queryDeleteUser({ variables: {id: oldData.id} })
                  resolve()
                }),
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => refetch(),
              }
            ]}
          />
        </Grid>
      </Grid>
  )
}

export default Users