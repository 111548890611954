import BackupIcon from '@material-ui/icons/Backup';
import BarChartIcon from '@material-ui/icons/BarChart'
import DescriptionIcon from '@material-ui/icons/Description'
import {Link} from "react-router-dom"
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PeopleIcon from '@material-ui/icons/People'
import React from 'react'
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'

export const mainListItems = (
  <div>
    {/* <ListItem button component={Link} to="/admin">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem> */}
    <ListItem button component={Link} to="/admin">
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button component={Link} to="/admin/upload">
      <ListItemIcon>
        <BackupIcon />
      </ListItemIcon>
      <ListItemText primary="Upload" />
    </ListItem>
    {/* <ListItem button component={Link} to="/admin/wrike">
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports Wrike" />
    </ListItem> */}
    <ListItem button component={Link} to="/admin/inventory">
      <ListItemIcon>
        <DescriptionIcon />
      </ListItemIcon>
      <ListItemText primary="Inventory" />
    </ListItem>
    <ListItem button component={Link} to="/admin/support">
      <ListItemIcon>
        <ViewCarouselIcon />
      </ListItemIcon>
      <ListItemText primary="Support" />
    </ListItem>
    <ListItem button component={Link} to="/admin/users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
    <ListItem button component={Link} to="/admin/statreports">
      <ListItemIcon>
        <RecentActorsIcon />
      </ListItemIcon>
      <ListItemText primary="Stat Reports" />
    </ListItem>
  </div>
)